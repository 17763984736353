import React from 'react'
import { customTitle } from '@/utils/customTitle'
import Head from '@/next/head'
import { AppPage } from '@/models/AppPage'
import { NotFoundPage } from '@/layouts/NotFoundPage'
import { NotFound } from '@/features/not-found'

const Page: AppPage = () => {
  return (
    <>
      <Head>
        <title>{customTitle('Страница не найдена')}</title>
      </Head>
      <NotFoundPage>
        <NotFound />
      </NotFoundPage>
    </>
  )
}

export default Page
