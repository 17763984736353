import React from 'react'
import { observer } from 'mobx-react-lite'
import NotFoundImage from './assets/404.svg'
import Image from '@/next/image'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { useRouter } from '@/next/router'
import { NotFoundWrapper, Title, Description, ButtonStyled } from './styled'

export const NotFound = observer(() => {
  const { routes, config } = useAppContext<AppContext>()
  const router = useRouter()

  const { isMobile } = config.device

  return (
    <NotFoundWrapper isMobile={isMobile}>
      <div>
        <Image width={isMobile && '300'} height={isMobile && '119'} src={NotFoundImage} alt="404" />
        <Title> упс, страница не найдена...</Title>
      </div>
      <div>
        <Description>Здесь ничего нет. Попробуйте найти на главной странице</Description>
        <ButtonStyled isMobile={isMobile} onClick={() => router.push(routes.home())}>
          На главную
        </ButtonStyled>
      </div>
    </NotFoundWrapper>
  )
})
