import styled from '@emotion/styled'
import { NavigationMobileMenu } from '@/features/navigation-menu'

export const NavigationMobileMenuSm = styled(NavigationMobileMenu)(({ theme }) => ({
  display: 'flex',

  [`${theme.media.lg}`]: {
    display: 'none',
  },
}))
