import React from 'react'
import styled from '@emotion/styled'
import { Body16, Headline24 } from '@platform-ui/typography'
import { Button } from '@platform-ui/components/Button'

export const NotFoundWrapper = styled.div<{ isMobile: boolean }>(({ theme, isMobile }) => {
  return {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    textAlign: 'center',
    padding: '44px 0 32px',

    [`${theme.media.md}`]: {
      justifyContent: !isMobile && 'center',
      padding: '20px 0',
    },
  }
})

export const Title = styled(Headline24)(({ theme }) => {
  return {
    color: theme.color['text/secondary'],
    marginBottom: 40,
    marginTop: 8,
    fontWeight: 400,

    [`${theme.media.md}`]: {
      fontWeight: 400,
    },
    [`${theme.media.lg}`]: {
      fontWeight: 400,
    },
  }
})

export const Description = styled(Body16)(({ theme }) => {
  return {
    color: theme.color['text/secondary'],
    maxWidth: 368,
    marginBottom: 16,
  }
})

export const ButtonStyled = styled(Button)<{ isMobile: boolean }>(({ theme, isMobile }) => {
  return {
    width: '100%',

    [`${theme.media.md}`]: {
      maxWidth: !isMobile && 176,
    },
  }
})
