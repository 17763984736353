import React from 'react'
import { observer } from 'mobx-react-lite'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import Header from '@/features/header'
import Footer from '@/features/footer'
import { Container } from '@platform-ui/components/Container'
import { NavigationMobileMenu } from '@/features/navigation-menu'
import { NavigationMobileMenuSm } from './styled'
import { AlertBanner } from '@/components/AlertBanner'

export interface DefaultPageProps {
  children: React.ReactNode
}

export const NotFoundPage = observer((props) => {
  let { children } = props
  const { config } = useAppContext<AppContext>()

  if (config === null) {
    return null
  }

  const { isMobile, isTablet } = config.device
  const isMobileDevice = isMobile || isTablet

  return (
    <>
      <Header />
      <AlertBanner />
      <Container as="main" flexSm>
        {children}
      </Container>
      <Footer />
      {isMobileDevice ? <NavigationMobileMenu /> : <NavigationMobileMenuSm />}
    </>
  )
})
